import { FC } from 'react';

import RichText from '@components/RichText/RichText';
import { useLinkComponent } from '@link';
import { Placeholder, wrap } from '@sitecore/common';
import { HeadingSize, TitleTextCTARendering } from '@sitecore/types/manual/TitleTextCTA';
import { ButtonLink, Heading, PageGrid, Stack, Stretch, Text, TextLink } from '@sparky';
import { HeadingProps } from '@sparky/types';

import { ChatLink } from './ChatLink';

const headingSizes: Record<HeadingSize, HeadingProps['size']> = {
  headingDefault: {
    initial: 'S',
    md: 'M',
  },
  headingHero: {
    initial: 'M',
    md: 'XL',
  },
  headingSub: {
    initial: 'XS',
    md: 'S',
  },
};

const TitleTextCTA: FC<TitleTextCTARendering> = ({ params, fields }) => {
  const { buttonLink, content, link, chatLink, title } = fields;
  const {
    anchorId,
    headingLevel,
    headingSize = 'headingDefault',
    textSize,
    buttonLinkAction,
    buttonLinkTone,
    linkColor,
  } = params;

  const guardedLinkColor =
    linkColor && ['linkBrand', 'linkPrimary', 'linkInverted'].includes(linkColor) ? linkColor : undefined;

  const Link = useLinkComponent();

  return (
    <PageGrid.Item gridColumn={{ initial: '1/-1', md: '1/-4' }}>
      <div id={anchorId}>
        <Stack gap="6">
          {(title?.value || title?.editable) && (
            <Heading as={headingLevel ?? 'h2'} size={headingSizes[headingSize]} color="inherit">
              {wrap(title)}
            </Heading>
          )}
          {(content?.value || content?.editable) && (
            <Text size={textSize ?? { initial: 'BodyM', md: 'BodyL' }}>
              {wrap(
                content,
                <RichText
                  html={content.value}
                  replacements={{
                    a: props => <TextLink {...props}>{props.children}</TextLink>,
                    strong: props => (
                      <Text display="inline" weight="bold">
                        {props.children}
                      </Text>
                    ),
                  }}
                />,
              )}
            </Text>
          )}
          <Placeholder name="jss-title-text-cta-main" />
          {((buttonLink?.value?.text && buttonLink.value.href) || buttonLink?.editable) && (
            <Stack.Item grow={false}>
              <Stretch width={{ initial: true, md: false }}>
                <Link editable={buttonLink.editable} linkType={buttonLink.value.linktype} linkValue={buttonLink.value}>
                  <ButtonLink action={buttonLinkAction} target={buttonLink.value.target} tone={buttonLinkTone}>
                    {buttonLink.value.text}
                  </ButtonLink>
                </Link>
              </Stretch>
            </Stack.Item>
          )}
          {((link?.value?.href && link.value.text) || link?.editable) && (
            <Stack.Item grow={false}>
              <Link editable={link.editable} linkType={link.value.linktype} linkValue={link.value}>
                <TextLink color={guardedLinkColor} emphasis="high" target={link.value.target}>
                  {link.value.text}
                </TextLink>
              </Link>
            </Stack.Item>
          )}
          {(chatLink?.value?.text || chatLink?.editable) && (
            <Stack.Item grow={false}>
              <ChatLink chatLink={chatLink} linkColor={linkColor} />
            </Stack.Item>
          )}
        </Stack>
      </div>
    </PageGrid.Item>
  );
};

export default TitleTextCTA;
